import { DialogService } from 'src/app/services/shared/dialog.service';
import { Router } from '@angular/router';
import { GeolocationService } from './geolocation.service';
import { ElementRef, Injectable, OnDestroy, ViewChild } from '@angular/core';
import { MaraudeHttpService } from './api/maraude.http.service';
import { MaraudeStore } from '../models/store/maraude-store.service';
import { Subscription, timer } from 'rxjs';
import { TripHttpService } from "./api/trip-http.service";
import * as moment from "moment/moment";
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class MaraudeService implements OnDestroy {

  sendLocationTimer = null;
  checkTaxiStatusSub: Subscription = null;
  checkTaxiOccupationSub: Subscription = null;
  checkHailsStatusSub: Subscription = null;

  constructor(
    private geolocationService: GeolocationService,
    private maraudeHttpService: MaraudeHttpService,
    private maraudeStore: MaraudeStore,
    private tripHttpService: TripHttpService,
    private alertController: AlertController,
    private dialogService: DialogService,
    private router: Router
  ) {
  }

  ngOnDestroy(): void {
    this.stop();
  }

  /**
   * Start maraude
   *
   * @param radius
   */
  async start(radius) {
    // try start backend maraude
    const startMaraude = await this.maraudeHttpService.startMaraude({ radius: this.maraudeStore.getCurrentRadius() });
    if (startMaraude.status !== 200) {
      return false;
    }

    // update some values
    this.maraudeStore.updateTaxiStatus(startMaraude.data);
    this.maraudeStore.setMaraudeStatus('on');
    this.maraudeStore.setTaxiOccupation('free');
    await this.launchSubscriptions();

    return true;
  }

  /**
   * Launch needed subscriptions
   *
   * @private
   */
  public async launchSubscriptions() {
    // try starting geolocalisation
    const startGeoloc = await this.geolocationService.launchGeolocation();
    this.maraudeStore.setGeolocationStatus('on');

    // set send location timer
    if (this.sendLocationTimer) {
      clearInterval(this.sendLocationTimer);
    }
    this.sendLocationTimer = setInterval(() => {
      this.maraudeHttpService.sendPosition(this.maraudeStore.getCurrentTaxiLocation());
    }, 5000);

    // set update taxis status from backend
    this.checkTaxiStatusSub = timer(0, 15000).subscribe(() => {
      this.maraudeHttpService.checkTaxiStatus().subscribe(
        (result) => {
          this.maraudeStore.updateTaxiStatus(result);
        }
      );
    });

    this.manageTaxiOccupation();
  }

  /**
   * Stop maraude
   */
  async stop() {
    await this.maraudeHttpService.stopMaraude();

    if (this.sendLocationTimer) {
      clearInterval(this.sendLocationTimer);
    }
    if (this.checkTaxiStatusSub) {
      this.checkTaxiStatusSub.unsubscribe();
    }
    if (this.checkTaxiOccupationSub) {
      this.checkTaxiOccupationSub.unsubscribe();
    }
    this.geolocationService.stopGeolocation();
    this.maraudeStore.setMaraudeStatus('off');
    this.maraudeStore.setTaxiOccupation('free');

  }

  /**
   * toggle taxi status free or occupied
   *
   * @param status
   */
  async toggleTaxiStatus(status) {
    // try start backend maraude
    const startMaraude = await this.maraudeHttpService.toggleTaxiStatus(status);
    if (startMaraude.status !== 200) {
      return false;
    }

    // update some values
    this.maraudeStore.updateTaxiStatus(startMaraude.data);

    return true;
  }


  /**
   * manage taxi occupation
   */
  manageTaxiOccupation() {
    if (this.checkTaxiOccupationSub) {
      this.checkTaxiOccupationSub.unsubscribe();
    }
    this.checkTaxiOccupationSub = this.maraudeStore.taxiOccupation.subscribe((status) => {
      switch (status) {
        case 'accepted_by_taxi':
          this.maraudeStore.stopCountdown();
          this.saveHail(5);
          break;
        case 'declined_by_taxi':
          this.saveHail(13);
          this.resetHails();
          break;
        case 'timeout_taxi':
          this.saveHail(14);
          this.resetHails();
          break;
        case 'accepted_by_customer':
          this.saveHail(6);
          break;
        case 'declined_by_customer':
        case 'timeout_customer':
        case 'timeout_accepted_by_customer':
        case 'incident_customer':
          this.saveHail(12);
          this.resetHails();
          break;
        case 'customer_on_board':
          moment.locale('fr');
          this.maraudeStore.setHailProp('start_hour', moment().format('HH:mm'));
          this.saveHail(9);
          break;
        case 'incident_taxi':
          moment.locale('fr');
          this.maraudeStore.setHailProp('end_hour', moment().format('HH:mm'));
          this.saveHail(15);
          this.resetHails();
          break;
        case 'finished':
          this.saveHail(10);
          this.resetHails();
          break;
        case 'uknown_error':
          this.saveHail(14);
          this.resetHails();
          break;
      }
    });
  }

  /**
   * receive an hail from notification
   *
   * @param notificationData
   */
  async askedProcess(notificationData) {
    const { type, hails_id, status, operateur, ...customer } = notificationData;
    console.log('hail received', notificationData);
    this.maraudeStore.createHail();
    this.maraudeStore.setCustomer(customer);
    this.maraudeStore.setHailsId(hails_id);

    // respond received by taxi to backend
    const responseStatus = await this.sendHailStatus('received_by_taxi');
console.log('send status ', responseStatus);
    if (responseStatus === 200) {
      this.startCheckHails(hails_id);
      this.maraudeStore.setTaxiOccupation('received_by_taxi');

      if (this.router.url.indexOf('maraude') === -1) {
        this.router.navigateByUrl('/maraude');
      }
    } else {
      this.maraudeStore.setTaxiOccupation('failure');
      this.resetHails();
    }
  }

  /**
   * send hail status
   */
  async sendHailStatus(status, reason = '', updateTaxiOccupation = true, incidentCustomerReason= '') {
    const hailsId = this.maraudeStore.getCurrentHailsId();
    let statusData = { id: hailsId, status };
    if (reason !== '') {
      statusData = { ...statusData, ...{ reason } };
    }
    if(incidentCustomerReason !== '') {
      statusData = { ...statusData, ...{reporting_customer: true, reporting_customer_reason: incidentCustomerReason}};
    }
    const response = await this.maraudeHttpService.sendStatus({ data: [statusData] });

    if (updateTaxiOccupation) { this.maraudeStore.setTaxiOccupation(status); }
    return response.status;
  }

  /**
   *
   */
  async resetHails() {
    if (this.checkHailsStatusSub) {
      console.log('STOP check hail status');
      this.checkHailsStatusSub.unsubscribe();
    }
    this.stopCheckHails();
    this.maraudeStore.stopCountdown();
    this.maraudeStore.setCustomer(null);
    this.maraudeStore.setHail(null);
    this.maraudeStore.setTaxiOccupation('free');
  }

  /**
   *
   */
  async startCheckHails(hailsId) {
    if (this.checkHailsStatusSub) {
      console.log('STOP check hail status');
      this.checkHailsStatusSub.unsubscribe();
    }
    this.checkHailsStatusSub = timer(0, 5000).subscribe(async () => {
      console.log('check hail status');
      const hailsStatusResponse = await this.maraudeHttpService.checkHailsStatus(hailsId);
      if (hailsStatusResponse.status === 200) {
        const { status, reason } = hailsStatusResponse.data;
        this.maraudeStore.setTaxiOccupation(hailsStatusResponse.data.status);
      } else {
        this.maraudeStore.setTaxiOccupation('uknown_error');
      }
    });

  }

  /**
   *
   */
  async stopCheckHails() {
    if (this.checkHailsStatusSub) {
      console.log('STOP check hail status');
      this.checkHailsStatusSub.unsubscribe();
    }
    this.checkHailsStatusSub = null;
  }

  /**
   * save hail in courses table
   * @param courseState
   */
  async saveHail(courseState) {
    console.log('SAVE HAIL ', courseState);

    const hail = this.maraudeStore.getCurrentHail();
    hail.course_state = courseState;

    const result = await this.tripHttpService.postItem(hail);
    console.log(result);
  }

  /**
   * Incident taxi reason
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  async presentRefusReason(): Promise<any> {
    const alert = await this.alertController.create({
      cssClass: 'alertMultilinesOptions',
      header: 'Justifier l\'annulation',
      inputs: [
        {
          name: 'traffic',
          type: 'radio',
          label: 'Pas dans le bon sens de circulation',
          checked: true,
        },
        {
          name: 'traffic_jam',
          type: 'radio',
          label: 'Embouteillage',
          value: 'traffic_jam'
        },
        {
          name: 'address',
          type: 'radio',
          label: 'La course me fait sortir de ma zone de prise en charge',
          value: 'address'
        },
        {
          name: 'breakdown',
          type: 'radio',
          label: 'Panne véhicule',
          value: 'breakdown'
        },
        {
          name: 'garbage_truck',
          type: 'radio',
          label: 'Bloqué derrière un camion',
          value: 'garbage_truck'
        },
      ],
      buttons: [
        {
          text: 'Retour',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Valider',
          role: 'ok',
          handler: (data) => {
            if (!data) { data = 'traffic'; }
            console.log(data);
            return { data };
          }
        }
      ]
    });

    await alert.present();
    return alert.onDidDismiss().then(data => data);
  }

  /**
   * Incident customer reason
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  async presentIncidentCustomerReason(): Promise<any> {
    const alert = await this.alertController.create({
      cssClass: 'alertMultilinesOptions',
      header: 'Justifier l\'incident',
      message: 'Si vous décidez de rapporter un incident, le client sera banni pendant 24h et cela mettra fin à la course',
      inputs: [
        {
          name: 'payment',
          type: 'radio',
          label: 'Refus de paiement',
          value: 'payment',
          checked: true
        },
        {
          name: 'courtesy',
          type: 'radio',
          label: 'Client discourtois, alcoolisé ou autre',
          value: 'courtesy'
        },
        {
          name: 'aggressive',
          type: 'radio',
          label: 'Client aggressif',
          value: 'aggressive'
        }
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Valider',
          role: 'ok',
          handler: (data) => {
            if (!data) { data = 'courtesy'; }
            console.log(data);
            return { data };
          }
        }
      ]
    });

    await alert.present();
    return alert.onDidDismiss().then(data => data);
  }

}
