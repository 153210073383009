import { MaraudeService } from './maraude.service';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token as PushNotificationToken } from '@capacitor/push-notifications';
import { Device, DeviceInfo } from '@capacitor/device';
import { UserHttpServiceService } from './api/user-http-service.service';
import { ContextService } from './context.service';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  token = null;
  deviceInfo: DeviceInfo;
  notifications: PushNotificationSchema[] = [];
  initmessage = false;

  constructor(
    private afMessaging: AngularFireMessaging,
    public contextService: ContextService,
    private alertCtrl: AlertController,
    public userHttpService: UserHttpServiceService,
    private maraudeService: MaraudeService,
  ) {

  }

  async requestPermission() {
    this.deviceInfo = await Device.getInfo();
    const deviceUuid = (await Device.getId()).uuid;
    const response = await this.checkDevice(deviceUuid);
    console.log('consent response : ', response);
    if (!response.data.consentment || !response.data.known_device) {
      if (this.deviceInfo.platform !== 'web') {
        this.requestMobilePermission(response);
      }
    }
  }

  async checkDevice(code) {
    const response = await this.userHttpService.checkDevices(this.contextService.token.access_token, code);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  async sendDevice(resources) {
    const response = await this.userHttpService.sendDevice(resources);
    if (response?.status === 200) {
      return response;
    } else {
      return false;
    }
  }


  async setConsent(type, value) {
    if (value) {
      const consentResult = await this.userHttpService.setConsent(type);
      if (consentResult.status === 200) {
        return true;
      } else {
        return consentResult.error[0];
      }
    } else {
      const consentResult = await this.userHttpService.unsetConsent(type);
      if (consentResult.status === 200) {
        return true;
      } else {
        return consentResult.error[0];
      }
    }
  }

  async getMessages() {
    this.deviceInfo = await Device.getInfo();
    if (this.deviceInfo.platform === 'web') {
      // this.getWebMessage();
    } else {
      this.getMobileMessages();
    }
    this.initmessage = true;
  }
  private requestMobilePermission(requestData) {
    console.log('REQUEST_PERMISSION');
    PushNotifications.requestPermissions().then(permission => {
      console.log('PERMISSION', permission.receive);
      if (permission.receive === 'granted') {
        PushNotifications.register();
      } else {
        console.log('NO_PERMISSION');
      }
    });

    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      console.log('TOKEN_VALUE', token.value);
      const deviceToken = {
        token: token.value,
        active: 1,
      };
      this.sendDevice(deviceToken);
      if (!requestData.data.consentment_exist) {
        this.setConsent('NOTIFICATIONS', true);
      }
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('PUSH_NOTIFICATION_ERROR', JSON.stringify(error));
    });
  }


  private getWebMessage() {
    return this.afMessaging.messages.subscribe(async (msg: any) => {
      const buttons: Array<any> = ['Ok'];
      const cancelButton = {
        text: 'Retour',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      };

      if (msg.data) {

      }
      const alert = await this.alertCtrl.create({
        header: msg.notification.title,
        message: msg.notification.body,
        buttons,
      });
      await alert.present();
    });

  }

  private async getMobileMessages() {
    console.log('addListener pushNotificationReceived');

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      this.receivedNotification(notification.data);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const data = notification.notification.data;
      this.receivedNotification(data);
    });
  }

  private async receivedNotification(notificationData) {
    const buttons: Array<any> = ['Ok'];

    console.log('DATA_NOTIFICATION', JSON.stringify(notificationData));

    if (notificationData.type === 'hails-asked') {
      this.maraudeService.askedProcess(notificationData);
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Reception d\'une notification',
        message: '',
        buttons,
      });
      await alert.present();
    }
  }
}
