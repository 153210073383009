export interface TaxiInterface {
  id: string;
  user_id: number;
  operator: string;
  ads_insee: string;
  ads_number: string;
  professional_licence: string;
  professional_licence_dpt: number;
  licence_plate: string;
  radius: number;
  crowfly_distance: string;
  rating: string;
  status: string;
  device_uuid: string;
}

export interface TaxiStatusInterface {
  taxi_id: string;
  status: string;
  external_status?: string;
  radius: number;
  maraude_state: string;
  hail_id: string;
  hail_state: string;
  hail: any;
  latitude: number;
  longitude: number;
}

export interface Coords {
  lat: number;
  lng: number;
}

export const TAXIEXTERNALSTATUS = {
  libre: 'LIBRE',
  station: 'STATION',
  charge: 'CHARGE',
  adestination: 'A DESTINATION',
  indisponible: 'INDISPONIBLE',
};
