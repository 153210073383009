import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayModeService {

  constructor() { }


  toggleDisplayMode = (checked) => {

    document.body.classList.toggle('dark', checked);

    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    // // Listen for changes to the prefers-color-scheme media query
    // prefersDark.onchange = (ev) => document.body.classList.toggle('dark', ev.matches);

  }
}
