export const environment = {
  production: true,
  authApi: 'https://api-dev.taxis-nantes.com/api/',
  dataApi: 'https://api-dev.taxis-nantes.com/api/v1/',
  driverApi: 'https://api-driver-dev.taxis-nantes.com/api/v1/',
  dispatchApi: 'https://api-dispatch-dev.taxis-nantes.com/api/',
  placeAPIKey: 'AIzaSyDNL8PbVaPKPawRfdJj1X8QQLgAKXVqprY',
  tenant: 'm-taxis-nantes-com',
  firebase: {
    apiKey: 'AIzaSyCo9JxODrtyQOtcmpNWUsNU62DOc0-Y0Jg',
    authDomain: 'taxis-nantes-chauffeurs.firebaseapp.com',
    projectId: 'taxis-nantes-chauffeurs',
    storageBucket: 'taxis-nantes-chauffeurs.appspot.com',
    messagingSenderId: '832923893514',
    appId: '1:832923893514:web:b269ee4e8815beb9c19013'
  }
};
