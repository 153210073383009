import { TripHttpService } from './../api/trip-http.service';
import { TaxiFolderHttpService } from './../api/taxi-folder.http.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserHttpServiceService } from '../api/user-http-service.service';
import { ContextService } from '../context.service';
import { Storage } from '@capacitor/storage';
import { IUserInterface } from 'src/app/models/interfaces/user-interface';
import { DriverFolderService } from './driver-folder.service';
import * as XLSX from 'xlsx';
import { DriverNotifsInterface } from 'src/app/models/interfaces/driver-notifs-interface';
import { NotificationHttpService } from '../api/notification-http.service';
import { DialogService } from '../shared/dialog.service';
import { NotificationData } from 'src/app/models/interfaces/notification-interface';
import {MaraudeState, MaraudeStore} from "../../models/store/maraude-store.service";
import {MaraudeHttpService} from "../api/maraude.http.service";
import {TaxiStatusInterface} from "../../models/interfaces/taxi-interface";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userdata: IUserInterface;
  localeConfig: any;
  notificationFoldersState: DriverNotifsInterface;
  numbersOfNotifsFolderState = 0;
  tabNotifications: Array<any> = [];
  tabIdNotifications: Array<any> = [];
  isInitialized = false;

  constructor(
    public userHttpService: UserHttpServiceService,
    public contextService: ContextService,
    private folderService: TaxiFolderHttpService,
    private coursesService: TripHttpService,
    private driverFolderService: DriverFolderService,
    private notificationHttpService: NotificationHttpService,
    private dialogService: DialogService,
    private maraudeStore: MaraudeStore,
    private maraudeHttpService: MaraudeHttpService,
    private router: Router,
  ) {
  }

  async init() {
    const localeData = await Storage.get({ key: 'atndriver.user' });
    const localeConfigJson = await Storage.get({ key: 'atndriver.config' });
    const localDocuments = await Storage.get({ key: 'atndriver.required_document' });

    if (localDocuments.value) {
      this.contextService.requiredDocuments.next(JSON.parse(localDocuments.value));
    }

    if (localeData.value) {
      this.userdata = JSON.parse(localeData.value);
      await this.getNotificationsFolderState(this.userdata.userdata.id);
      await this.getMe(true);
      await this.contextService.setUser(this.userdata);
      await this.setHomepage();
    } else {
      this.userdata = { token_expire: 0 };
      this.userdata.homePage = 'login';
      await this.contextService.resetUser();
    }
    this.isInitialized = true;
    this.router.navigate([this.userdata.homePage]);

    if (localeConfigJson.value) {
      this.localeConfig = await JSON.parse(localeConfigJson.value);
    } else {
      this.localeConfig = {
        splitmenu: true,
        splitmenusize: 1200,
      };
    }

  }

  async updateUser() {
    await this.getMe();
    await this.contextService.setUser(this.userdata);
  }

  async saveUser() {
    this.userdata.homePage = '';
    await Storage.set({
      key: 'atndriver.user',
      value: JSON.stringify(this.userdata),
    });
  }

  async saveLocaleConfig() {
    await Storage.set({
      key: 'atndriver.config',
      value: JSON.stringify(this.localeConfig),
    });
  }

  isLoggedIn() {
    const current = new Date();
    const timestamp = current.getTime();

    return !(!this.userdata || this.userdata.token_expire < timestamp);
  }

  async login(credential: any) {
    const loginResult = await this.userHttpService.login(credential);

    if (loginResult.status === 200) {
      this.userdata.token = loginResult.data;
      const current = new Date();
      this.userdata.token_expire =
        current.getTime() + this.userdata.token.expires_in * 1000;
      const me = await this.userHttpService.getMe(
        this.userdata.token.access_token
      );
      this.userdata.userdata = me.data;
      console.log('get folder');
      await this.getFolder();
      console.log('end get folder');
      this.contextService.setUser(this.userdata);
      await this.saveUser();
      await this.setHomepage();

      return true;
    }

    return false;
  }

  async logout() {
    const loginResult = await this.userHttpService.logout(this.getToken());

    if (loginResult.status === 200) {
      this.userdata = { token_expire: 0 };
      await Storage.remove({ key: 'atndriver.user' });
      this.contextService.resetUser();
      return true;
    }

    return false;
  }

  hasRole(role: string) {
    if (!this.userdata || !this.userdata.userdata) {
      return false;
    } else {
      return this.userdata.userdata.roles.indexOf(role) > -1;
    }
  }

  getToken() {
    if (this.isLoggedIn()) {
      return this.userdata.token.access_token;
    }
    return '';
  }

  async getMe(refreshToken = false) {
    const me = await this.userHttpService.getMe(
      this.userdata.token.access_token,
      refreshToken
    );
    if (me.status === 200) {
      this.userdata.userdata = me.data;

      if(refreshToken && me.data.token) {
        this.userdata.token = me.data.token;
        this.contextService.setUser(this.userdata);
      }
      this.contextService.user.consents = this.userdata.userdata.consents;
      await this.getFolder();
      await this.getRequiredDocuments();
      await this.saveUser();
      return true;
    } else {
      this.userdata = { token_expire: 0 };
      await Storage.remove({ key: 'atndriver.user' });
      return false;
    }
  }

  async getFolder(userId: string = 'me') {
    const folder = await this.folderService.getFolder(userId);
    if (folder.status === 200) {
      this.userdata.userdata.folder = folder.data;
      return true;
    } else {
      return false;
    }
  }

  async getCourses(userId: string = 'me', body: any) {
    const courses = await this.coursesService.getCourses(userId, body);
    if (courses.status === 200) {
      this.contextService.courses.next(courses.data);
      console.log(courses.data);
      return true;
    } else {
      return false;
    }
  }

  async exportToExcel(data, filename) {
    {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const wb: XLSX.WorkBook = { SheetNames: ['data'], Sheets: { data: ws } };
      // XLSX.utils.book_append_sheet(wb, ws, filename);
      XLSX.writeFile(wb, filename + '.xlsx');
    }
  }

  async getRequiredDocuments() {
    const folder = await this.driverFolderService.getRequiredDocuments(this.userdata.userdata.company_id);
    if (folder.status === 200) {
      await Storage.set({
        key: 'atndriver.required_document',
        value: JSON.stringify(folder.data),
      });
      this.contextService.requiredDocuments.next(folder.data);
      return true;
    } else {
      return false;
    }
  }

  async updateMe(user) {
    const me = await this.userHttpService.updateUser(user);
    if (me.status === 200) {
      this.userdata.userdata = me.data;
      return await this.getMe();
    } else {
      return false;
    }
  }

  async setHomepage() {
    let maraudeState='off';
    if(this.contextService.deviceInfo.platform !== 'web') {
      try {
        const state: TaxiStatusInterface = await this.maraudeHttpService.checkTaxiStatus().toPromise();
        if (state) {
          maraudeState = state.maraude_state;
          this.maraudeStore.setMaraudeState({
            maraudeStatus: state.maraude_state,
            taxiStatus: state.status,
            taxiOccupation: state.hail_state ?? 'free',
            hailsId: state.hail_id ?? null,
            hail: state.hail,
            geolocationStatus: 'off',
          } as MaraudeState);
        }
      }
      catch ($e) {
        console.log($e);
      }
    }
    if(maraudeState==='on') {
      this.userdata.homePage = '/maraude';
    }
    else {
      this.userdata.homePage = this.contextService.userHasAllConsent() ? '/driver-file' : '/cgv';
    }
    console.log('homepage ', this.userdata.homePage);
  }

  async register(credentials) {
    const registerResult = await this.userHttpService.register(credentials);
    if (registerResult.status === 201) {
      return true;
    } else if (registerResult.status === 420) {
      return 'badValidationCode';
    } else {
      return registerResult.error;
    }
  }

  async resetPassword(credentials) {
    const resetPasswordResult = await this.userHttpService.resetPassword(
      credentials
    );
    if (resetPasswordResult.status === 200) {
      return true;
    } else {
      return resetPasswordResult.error[0];
    }
  }

  async setConsent(type, value) {
    if (value) {
      const consentResult = await this.userHttpService.setConsent(type);
      if (consentResult.status === 200) {
        this.getMe();
        return true;
      } else {
        return consentResult.error[0];
      }
    } else {
      const consentResult = await this.userHttpService.unsetConsent(type);
      if (consentResult.status === 200) {
        this.getMe();
        return true;
      } else {
        return consentResult.error[0];
      }
    }
  }

  async setFavorite(favoriteId, place) {
    const favoriteResult = await this.userHttpService.setFavorite(
      favoriteId,
      place
    );
    if (favoriteResult.status === 200) {
      const existId = this.contextService.favorites.findIndex(
        (item) => item.place_id === favoriteId
      );
      if (existId < 0) {
        this.contextService.favorites.push({
          place_id: favoriteId,
          place,
        });
      }
    } else {
      return favoriteResult.error[0];
    }
  }

  async unsetFavorite(favoriteId) {
    const favoriteResult = await this.userHttpService.unsetFavorite(favoriteId);
    if (favoriteResult.status === 200) {
      const existId = this.contextService.favorites.findIndex(
        (item) => item.place_id === favoriteId
      );
      if (existId >= 0) {
        this.contextService.favorites.splice(existId, 1);
      } else {
        return favoriteResult.error[0];
      }
    }
  }

  async checkDevice(code) {
    const response = await this.userHttpService.checkDevices(
      this.userdata.token.access_token,
      code
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  async sendDevice(resources) {
    const response = await this.userHttpService.sendDevice(
      resources
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  async checkEmailExist(email) {
    const result: any = await this.userHttpService.checkEmailExist(email);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  async sendConnectedNotification() {
    const result: any = await this.userHttpService.sendConnectedNotification();
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  async getReadCgvValue() {
    const readcgv = await Storage.get({ key: 'atndriver.readcgv' });
    return readcgv;
  }

  async deleteAccount() {
    const response = await this.userHttpService.deleteAccount();
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  /**************************************************************************
     * Notifications
     */

   async getNotificationsFolderState(userId) {
    this.numbersOfNotifsFolderState = 0;
    this.tabNotifications = [];
    this.notificationHttpService.getNotificationFolderState(userId,).then((requestResult) => {
        if (requestResult.status === 200) {
            this.tabNotifications = requestResult.data;
            this.notificationFoldersState = requestResult.data;
            this.numbersOfNotifsFolderState = requestResult.data.length;
            if(this.numbersOfNotifsFolderState > 0){
              this.tabNotifications.forEach(item => {
                this.openDialogNotifFolderState(item, userId);
                this.tabIdNotifications.push(item.id);
              });
            }
            return true;
        } else {
            return false;
        }
    });
  }

  async updateNotifications(tabNotifId: Array<any>, userId) {
    const updateResult = await this.notificationHttpService.upddateNotifReadAt(
      tabNotifId, userId
    );
    if (updateResult.status === 200) {
        this.getNotificationsFolderState(userId);//Mise à jour de la liste
        return true;
    } else {
        return false;
    }
}

  openDialogNotifFolderState(notif: NotificationData, userId){
    console.log('notif passed', notif);
    console.log('userId passed', userId);
    const title = 'Notification Dossier';
    if(notif.user_folder_state){
      console.log('user_folder_state', notif.user_folder_state);
      console.log('user_folder_state Nm', Number(notif.user_folder_state));
      switch (Number(notif.user_folder_state)) {
        case 3:
          this.dialogService
          .alert(
            title,
            'Félicitation, votre dossier est complet',
            'checkmark',
            'success', (() => {
              this.updateNotifications(this.tabIdNotifications,userId);//on marque la notif comme lu
              this.router.navigateByUrl('/maraude');
            }));
          break;
        case 4:
          this.dialogService
          .alert(
            title,
            'Désolé, votre dossier n\'est pas recevable. Veuillez faire les corrections nécessaire et resoumettre votre dossier',
            'alert-circle',
            'danger', (() => {
              this.updateNotifications(this.tabIdNotifications,userId);//on marque la notif comme lu
            }));
          break;
        case 90:
          this.dialogService
          .alert(
            title,
            'Votre accès aux courses est bloqué pour l\'instant. Mettez à jour les documents expirés ou contactez un administrateur',
            'alert-circle',
            'danger', (() => {
              this.updateNotifications(this.tabIdNotifications,userId);//on marque la notif comme lu
            }));
          break;
        default:
          break;
      }
    }

  }
  /**
   * End Notifications
   */
}
