import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DsDialogConfirmationComponent implements OnInit {
  @Input() type: 'primary' | 'success' | 'danger' | 'warning' = 'primary';
  @Input() title: string;
  @Input() message: string;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit(): void {
  }

  buttonClick(type: 'yes' | 'no') {
    this.modalController.dismiss(type);
  }

}
