import {MaraudeService} from './services/maraude.service';
import {MessagingService} from './services/messaging.service';
import {DisplayModeService} from './services/display-mode.service';
import {Capacitor, Plugins} from '@capacitor/core';
import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';

import {Platform, ToastController, ViewDidEnter, ViewWillLeave, AlertController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UserService} from './services/dataobject/user.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {ContextService} from './services/context.service';
import {Title} from '@angular/platform-browser';
import {Observable, of, Subscription} from 'rxjs';
import {getFolderState, userFolderStates} from './models/interfaces/userFolder-interface';

import {Share} from '@capacitor/share';
import {Device, DeviceInfo} from '@capacitor/device';
import {App} from '@capacitor/app';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements ViewDidEnter, OnInit, OnDestroy, ViewWillLeave {
  title = 'OPENTAXIS';
  loaded = false;
  currentUrl = '';
  modes = [
    {label: 'Night', icon: 'moon', index: 0, class: 'dark'},
    {label: 'Sunny', icon: 'sunny', index: 1, class: 'sunny'},
    {label: 'Automatic', icon: 'contrast', index: 2, class: ''},
  ];
  selectedMode: any;
  public selectedIndex = 0;
  public deviceInfos: DeviceInfo = null;
  public state = 'windows';
  public menuItems = [
    {label: 'Mon dossier chauffeur', icon: 'folder-open', url: '/driver-file'},
    {label: 'Maraude électronique', icon: 'navigate', url: '/maraude'},
    {label: 'Mes courses', icon: 'list', url: '/trips'},
    // {label: 'Partager l\'application', icon: 'share-social', url: '/share', option: 'share'},
    {label: 'Infos coopérative', icon: 'share-social', url: '/infos-coop'},
    {label: 'Paramètres', icon: 'cog', url: '/parameters'},
  ];
  userFolderSub: Subscription;
  userFolderState: any;
  appVersion: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public userService: UserService,
    public contextService: ContextService,
    private router: Router,
    private zone: NgZone,
    private toastCtrl: ToastController,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private displayModeService: DisplayModeService,
    private messagingService: MessagingService,
    private maraudeService: MaraudeService
  ) {
    this.selectedMode = this.modes[2];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        const child = this.activatedRoute.firstChild;
        if (child && child.snapshot.data.title) {
          this.titleService.setTitle(child.snapshot.data.title);
        }
      }
    });

    this.initializeApp();

  }

  ngOnDestroy(): void {
    this.userFolderSub.unsubscribe();
  }

  async ngOnInit() {
    await this.userService.init();
    await this.contextService.init();
    this.deviceInfos = this.contextService.deviceInfo;
    if (this.deviceInfos.platform !== 'web') {
      const appInfos = await App.getInfo();
      this.appVersion = appInfos.version;
    }
    // filter maraude for cooperative informations
    if(this.contextService.user.company_id!=='64DDBCDE-C44D-4CD1-B45F-8782B1BA97B8') {
      this.menuItems = this.menuItems.filter(m => m.url !== '/infos-coop');
    }

    // filter menu for maraude & share service
    this.userFolderSub = this.contextService.userFolder.subscribe(
      userFolder => {
        if(userFolder) {
          if (userFolder.state !== userFolderStates.valid || this.deviceInfos.platform === 'web') {
            this.menuItems = this.menuItems.filter(m => m.url !== '/maraude' && m.url !== '/share');
          }

          // user folder state label
          this.userFolderState = getFolderState(userFolder.state);
        }
      }
    );
    this.messagingService.getMessages();
    this.messagingService.requestPermission();
  }

  goTo = async (menuItem) => {
    if (menuItem?.option) {
      await Share.share({
        title: 'Partager l\'application chauffeur avec',
        text: 'Really awesome thing you need to see right meow',
        url: Capacitor.getPlatform() === 'ios' ? 'http://ionicframework.com/' : 'http://ionicframework.com/',
        dialogTitle: 'Share with drivers',
      });
    } else {
      this.router.navigateByUrl(menuItem.url);
    }
  };

  ionViewDidEnter(): void {
    const appsplash = document.getElementById('appsplash');
    if (appsplash) {
      appsplash.remove();
    }
  }

  ionViewWillLeave(): void {

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    // App.addListener('appUrlOpen', (data: any) => {
    //   this.zone.run(() => {
    //     const slug = data.url.split('.com').pop();
    //     console.log('slug ', slug);
    //     if (slug) {
    //       this.router.navigateByUrl(slug);
    //     }
    //   });
    // });
  }

  async logout() {
    await this.userService.logout();
    this.router.navigate(['/login']);
  }

  async deleteToken() {
    const toast = await this.toastCtrl.create({
      message: 'Token removed',
      duration: 2000
    });
    toast.present();
  }

  toggleMode = async () => {
    const mode = {...this.selectedMode};
    this.selectedMode = mode.index === 2 ? this.modes[0] : this.modes[mode.index + 1];
    (await this.toastCtrl.create({message: `Mode ${this.selectedMode.label} activé`, duration: 2000})).present();
    document.body.classList.remove(...['dark', 'sunny']);
    document.body.classList.add(this.selectedMode.class);
  };

  getInitial = () => {
    const splited = this.userService.contextService?.user?.name?.split(' ');
    return `${splited ? splited[0]?.substr(0, 1)?.toUpperCase() || '' + ' ' + splited[1]?.substr(0, 1)?.toUpperCase() || '' : ''}`;
  };

  isActivatedMenu = (url: string) => this.currentUrl.indexOf(url) > -1;
}
