import {IVehicleControl} from './../../models/interfaces/vehicle-control-interface';
import {IEntreprise} from './../../models/interfaces/entreprise-interface';
import {IInsuranceDocument} from './../../models/interfaces/doc-insurance-interface';
import {IProfessionalLicense} from './../../models/interfaces/professionnal-license-interface';
import {IVehicleDocument} from './../../models/interfaces/doc-vehicle-interface';
import {IDriverLicenseDocument} from './../../models/interfaces/doc-driver-license-interface';
import {IAdsDocument} from './../../models/interfaces/doc-ads-interface';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DriverFileHttpService {
  apiUrl = environment.driverApi;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * getRequiredDocuments
   *
   * @param companyId Credentials
   */
  async getRequiredDocuments(companyId: string) {
    const url = `${this.apiUrl}companies/${companyId}/required_documents`;

    return await this.httpClient
      .get<any>(url)
      .toPromise()
      .then((data) => ({status: 200, data}))
      .catch((error) => error);
  }

  /**
   * Post draft Doc
   *
   * @param docType
   * @param data
   */
  async postDraftDoc(docType, data) {
    const url = `${this.apiUrl}users/me/draftdoc/${docType}`;

    return await this.httpClient
      .post<any>(url, data)
      .toPromise()
      .then((data) => ({status: 200, data}))
      .catch((error) => error);
  }

  /**
   * Post Driver folder
   *
   */
  async postDriverFolder() {
    const url = `${this.apiUrl}users/me/postfolder`;
    const data = {};

    return await this.httpClient
      .put<any>(url, data)
      .toPromise()
      .then((data) => ({status: 200, data}))
      .catch((error) => error);
  }

  /**
   * Post Driver Company
   *
   * @param companyID
   * @constructor
   */
  async setDriverCompany(companyID) {
    const url = `${this.apiUrl}users/me/company/${companyID}`;
    const data = {};

    return await this.httpClient
      .patch<any>(url, data)
      .toPromise()
      .then((data) => ({status: 200, data}))
      .catch((error) => error);
  }
}
