import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() type: "primary" | "success" | "danger" | "warning" = 'primary';
  @Input() icon: string = 'checkmark';
  @Input() title: string;
  @Input() message: string;
  @Input() btnContent: string = 'OK';

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() { }

  okClick = () => {
    this.modalController.dismiss();
  }

}
