import {Injectable} from '@angular/core';
import {HttpResultInterface} from 'src/app/models/interfaces/http-list-meta-interface';
import {CrudHttpService} from './crud.http.service';
import * as XLSX from 'xlsx';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Trip} from "../../models/interfaces/trip-interface";

@Injectable({
  providedIn: 'root'
})
export class TripHttpService {
  private prefix = 'course';

  constructor(private crudHttpService: CrudHttpService, private httpClient: HttpClient) {
  }

  getCourses = async (userId: string, body: any):
    Promise<HttpResultInterface> => await this.crudHttpService.save<any>(this.prefix, userId, body);

  requestOrders = async (userId: string, body: any):
    Promise<HttpResultInterface> => await this.crudHttpService.save<any>(`${this.prefix}-invoice`, userId, body);

  async exportToExcel(data, filename) {
    {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, filename);
      XLSX.writeFile(wb, filename + '.xlsx');
    }
  }

  /**
   *
   * @param lat
   * @param lng
   */
  async reverseGeocode(lat, lng) {
    const url = `${environment.authApi}v1/public/reversegeocode?lat=${lat}&lng=${lng}`;

    try {
      const adrResponse: any = await this.httpClient.get<any>(url).toPromise();
      return adrResponse.address;
    } catch (e) {
      return '';
    }
  }

  /**
   *
   * @param body
   */
  async postItem(body: Trip): Promise<any> {
    const url = environment.dispatchApi + 'v1/trips';

    return await this.httpClient
      .post<Trip>(url, body)
      .toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error}));
  }

}
