import {IToken, IUserdata, IUserInterface} from './../models/interfaces/user-interface';
import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Device, DeviceInfo} from '@capacitor/device';
import {BehaviorSubject} from 'rxjs';
import {IUserFolder} from '../models/interfaces/userFolder-interface';
import {Storage} from "@capacitor/storage";
import {App, AppInfo} from "@capacitor/app";

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  user: IUserdata = null;
  userContext: number;
  roles: Array<string>;
  permissions: Array<string>;
  favorites: Array<any>;
  token: IToken;
  tokenexpire: number;
  hostname: string;
  deviceInfo: DeviceInfo;
  appInfo: AppInfo;
  deviceId: any;
  country: any;
  preferences: any = {};

  neededConsents = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cgu_driver_2022_1: 0,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    policy_driver_2022_1: 0,
  };


  /**
   * user folder
   */
  userFolder = new BehaviorSubject<IUserFolder>(null);

  /**
   * Required Documents
   */
  requiredDocuments = new BehaviorSubject<any>(null);

  /**
   * Course
   */
  courses = new BehaviorSubject<any>(null);

  contextTypes = {
    guest: 0,
    user: 1,
    relay: 2,
    company: 3,
  };

  constructor(public platform: Platform) {
    this.userContext = this.contextTypes.guest;
    this.hostname = window.location.origin;
    this.init();
  }

  async init() {
    this.deviceInfo = await Device.getInfo();
    this.deviceId = await Device.getId();
    try {
      this.appInfo = await App.getInfo();
    }
    catch (e) {};
    this.loadPreference();
  }

  setUser(userData: IUserInterface) {
    this.user = userData.userdata;
    this.token = userData.token;
    this.tokenexpire = userData.token_expire;
    this.roles = userData.userdata?.roles;
    this.favorites = userData.userdata.favorites ? userData.userdata.favorites.data : null;
    this.userFolder.next(userData.userdata.folder);
    this.checkConsents(userData.userdata.consents.data);

    this.userContext = this.contextTypes.user;
  }

  resetUser() {
    this.userContext = this.contextTypes.guest;
    this.user = null;
    this.token = null;
    this.tokenexpire = null;
    this.roles = null;
    this.favorites = null;

  }

  isGuest() {
    return this.userContext === this.contextTypes.guest;
  }

  is(contextKey) {
    return this.userContext === this.contextTypes[contextKey];
  }

  updateDocument(doc: string, value: any) {
    this.user.folder[doc] = value;
    this.userFolder.next(this.user.folder);
  }

  checkConsents(consentsData: Array<any>) {
    const check = (consent) => {
      const result = consentsData.filter((item) => item.consent_type === consent);
      return result.length === 0 ? 0 : 1;
    };
    this.neededConsents.cgu_driver_2022_1 = check('cgu_driver_2022_1');
    this.neededConsents.policy_driver_2022_1 = check('policy_driver_2022_1');
  }

  userHasAllConsent() {
    return this.neededConsents.cgu_driver_2022_1 + this.neededConsents.policy_driver_2022_1 === 2;
  }

  userHasConsent(consentType: string) {
    const result = this.user.consents.data.filter((item) => item.consent_type === consentType);
    return result.length === 0 ? 0 : 1;
  }

  canPostUserFolder() {
    const requiredDocs = this.requiredDocuments.getValue();
    const uncompletedRequiredDoc = requiredDocs.folder.filter(item => !this.user.folder[item.document_type_id] && item.required === 1);

    return uncompletedRequiredDoc.length > 0 ? uncompletedRequiredDoc : true;
  }

  async loadPreference() {
    const p = await Storage.get({
      key: 'atndriver.preference'
    });
    console.log(p);
    if (p.value) {
      this.preferences = JSON.parse(p.value);
    } else {
      this.preferences = {};
    }
    console.log('load preferences ', this.preferences);
  }

  async savePreference(prop, value) {
    console.log('save preference', this.preferences);
    this.preferences[prop] = value;
    console.log('save preference', this.preferences);
    await Storage.set({
      key: 'atndriver.preference',
      value: JSON.stringify(this.preferences),
    });
  }

}
