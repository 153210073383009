import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpResultInterface} from '../../models/interfaces/http-list-meta-interface';
import {TaxiInterface, TaxiStatusInterface} from '../../models/interfaces/taxi-interface';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {MaraudeStore} from "../../models/store/maraude-store.service";

@Injectable({
  providedIn: 'root'
})
export class MaraudeHttpService {
  nextSendTimestamp: number;
  private prefix = `${environment.dispatchApi}v1`;

  constructor(private httpClient: HttpClient, private maraudeStore: MaraudeStore) {
    this.nextSendTimestamp = moment.now() + 10000;
  }

  /**
   * check taxi and return taxi interface
   */
  checkTaxi = async (): Promise<HttpResultInterface<any>> =>
    await this.httpClient.get<TaxiInterface>(`${this.prefix}/taxi/checktaxi`).toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error: error.error}));

  /**
   * Check taxi status
   */
  checkTaxiStatus = (): Observable<any> => this.httpClient.get<any>(`${this.prefix}/taxi/checkstatus`);

  /**
   * Check hails status
   */
  checkHailsStatus = async (hailsId: string): Promise<HttpResultInterface<any>> =>
    await this.httpClient.get<any>(`${this.prefix}/hails/${hailsId}`).toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error: error.error}));

  /**
   *
   * @param taxiData
   */
  startMaraude = async (taxiData: any): Promise<HttpResultInterface<any>> =>
    await this.httpClient.patch<TaxiStatusInterface>(`${this.prefix}/maraude/start`, taxiData).toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error: error.error}));

  /**
   * stop maraude
   */
  stopMaraude = async (): Promise<HttpResultInterface<any>> =>
    await this.httpClient.patch<TaxiStatusInterface>(`${this.prefix}/maraude/stop`, {}).toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error: error.error}));

  /**
   * manual change taxi state
   */
  toggleTaxiStatus = async (status): Promise<HttpResultInterface<any>> =>
    await this.httpClient.patch<TaxiStatusInterface>(`${this.prefix}/maraude/toggletaxistatus`, {status}).toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error: error.error}));

  /**
   * Send taxi location
   *
   * @param location
   */
  sendPosition = async (location) => {
    //// post to api dispatch
    if (moment.now() > this.nextSendTimestamp) {
      console.log('send Position', location);
      this.maraudeStore.pingLocation.next(true);
      this.nextSendTimestamp = moment.now() + 10000;
      const url = environment.dispatchApi + 'v2/taxi/storeposition';
      const data = {
        type: 'custom location listener',
        data: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          accuracy: location.coords.accuracy
        },
      };
      return await this.httpClient.post(url, data)
        .toPromise()
        .then(data2 => ({status: 201, data2}))
        .catch(error => ({status: error.status, message: error.message, error: error.error.errors}));
    }

  };

  /**
   *
   * @param statusData
   */
  sendStatus = async (statusData): Promise<HttpResultInterface<any>> =>
    await this.httpClient.post<TaxiStatusInterface>(`${this.prefix}/hails`, statusData).toPromise()
      .then(data => ({status: 200, data}))
      .catch(error => ({status: error.status, error: error.error}));

}
