import { ILicenseTransport } from './license-transport-interface';
import { RcProDocument } from './rc-pro-interface';
import { FormationContinueDocument } from './formation-continue-interface';
import { LicenseMarchandiseDocument } from './license-marchandise-interface';
import { IVisiteMedicale } from './visite-medicale-interface';
import { IVehicleControl } from './vehicle-control-interface';
import { IVehicleDocument } from './doc-vehicle-interface';
import { IProfessionalLicense } from './professionnal-license-interface';
import { IInsuranceDocument } from './doc-insurance-interface';
import { IDriverLicenseDocument } from './doc-driver-license-interface';
import { IEntreprise } from './entreprise-interface';
import { IAdsDocument } from './doc-ads-interface';

export interface IUserFolder {
  state: number;
  requiredDoc?: string[];
  ads?: IAdsDocument;
  entreprise?: IEntreprise;
  professional_license?: IProfessionalLicense;
  driver_license?: IDriverLicenseDocument;
  vehicle?: IVehicleDocument;
  insurance?: IInsuranceDocument;
  vehicle_control?: IVehicleControl;
  visite_medicale?: IVisiteMedicale;
  license_transport?: ILicenseTransport;
  license_marchandise?: LicenseMarchandiseDocument;
  formation_continue?: FormationContinueDocument;
  rc_pro?: RcProDocument;
  internal_code?: string;
}
export interface IUserDoc {
  folder?: any[];
}

export const userFolderStates = {
  draft: 1,
  waiting: 2,
  valid: 3,
  incomplete: 4,
  blocked: 90,
  closed: 99,
};


export const getFolderState = (step) => {
  switch (step) {
    case 1: return { label: 'Dossier non déposé', icon: 'bookmark', color: 'warning' };
    case 2: return { label: 'Dossier déposé ', icon: 'timer', color: 'warning' };
    case 3: return { label: 'Dossier validé', icon: 'checkmark', color: 'success' };
    case 4: return { label: 'Dossier incomplet', icon: 'close', color: 'danger' };
    case 90: return { label: 'Dossier bloqué', icon: 'close', color: 'danger' };
    case 99: return { label: 'Dossier fermé', icon: 'close', color: 'danger' };
    default: return null;
  }
};


export const getFolderLabel = (folderName) => {
  switch (folderName) {
    case 'ads': return 'ads_long';
    case 'entreprise': return 'entreprise';
    case 'driver_license': return 'driver_license';
    case 'vehicle': return 'vehicle';
    case 'insurance': return 'insurance';
    case 'professional_license': return 'pl_long';
    case 'vehicle_control': return 'vehicle_control';
    case 'rc_pro': return 'rcpro_title';
    case 'license_marchandise': return 'ml_long';
    case 'license_transport': return 'tl_long';
    case 'formation_continue': return 'fo_continue';
    case 'visite_medicale': return 'vm_long';
    default: return '';
  }
};

