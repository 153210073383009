import {
  IUserFolder,
  IUserDoc,
} from './../../models/interfaces/userFolder-interface';
import { TaxiFolderHttpService } from './../api/taxi-folder.http.service';
import { DocInsuranceHttpService } from './../api/doc-insurance.http.service';
import { DocEntrepriseHttpService } from './../api/doc-entreprise.http.service';
import { DocProfessionalLicenseHttpService } from './../api/doc-professional-license.http.service';
import { DocVehicleControlHttpService } from './../api/doc-vehicle-control.http.service';
import { DocDriverLicenseHttpService } from './../api/doc-driver-license.http.service';
import { DocAdsHttpService } from './../api/doc-ads.http.service';
import { DriverFileHttpService } from '../api/driver-file.http.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ContextService } from '../context.service';
import { Storage } from '@capacitor/storage';
import { UserHttpServiceService } from '../api/user-http-service.service';
import { IUserInterface } from 'src/app/models/interfaces/user-interface';
import { DocVehicleHttpService } from '../api/doc-vehicle.http.service';

@Injectable({
  providedIn: 'root',
})
export class DriverFolderService {

  constructor(
    public driverFileHttpService: DriverFileHttpService,
  ) { }

  async getRequiredDocuments(companyId) {
    return await this.driverFileHttpService.getRequiredDocuments(companyId);
  }

  async postDriverFolder() {
    return await this.driverFileHttpService.postDriverFolder();
  }

  async setDriverCompany(companyID) {
    return await this.driverFileHttpService.setDriverCompany(companyID);
  }

}
