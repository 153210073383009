import {AuthGuardService} from './services/auth-guard.service';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    data: {title: 'Login'}
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordPageModule),
    data: {title: 'Mot de passe oublié'}
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule),
    data: {title: 'Inscription'}
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
    canActivate: [AuthGuardService],
    data: {roles: ['relay admin', 'relay', 'user'], title: 'Mon compte'}
  },
  {
    path: 'parameters',
    loadChildren: () => import('./pages/parameters/parameters.module').then(m => m.ParametersPageModule),
    canActivate: [AuthGuardService],
    data: {roles: ['relay admin', 'relay', 'user'], title: 'Parametres'}
  },
  {path: 'cgv', loadChildren: () => import('./pages/cgv/cgv.module').then(m => m.CgvPageModule)},
  {
    path: 'maraude',
    loadChildren: () => import('./pages/maraude/maraude.module').then(m => m.MaraudePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'driver-file',
    loadChildren: () => import('./pages/driver-file/driver-file.module').then(m => m.DriverFilePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'maraude',
    loadChildren: () => import('./pages/maraude/maraude.module').then(m => m.MaraudePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'trips',
    loadChildren: () => import('./pages/trips/trips.module').then(m => m.TripsPageModule),
    canActivate: [AuthGuardService],
  },
  {path: '', redirectTo: 'driver-file', pathMatch: 'full'},  {
    path: 'infos-coop',
    loadChildren: () => import('./pages/infos-coop/infos-coop.module').then( m => m.InfosCoopPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
