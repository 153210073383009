import { DsDialogConfirmationComponent } from './../../design-system/components/dialog-confirmation/dialog-confirmation.component';
import { DialogComponent } from './../../components/dialog/dialog.component';
import {Injectable, Output} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private modalController: ModalController
  ) {

  }

  alert = async (title: string, message: string, icon: string, type = "primary", callback = null) => {
    const modal = await this.modalController.create({
      component: DialogComponent,
      componentProps: { title, message, icon, type },
      cssClass: 'dialog-modal',
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(data => {
      if (callback) {
        callback(data);
      }
    });
    modal.present();
  };

  confirm = async (title: string, message: string, type = 'primary', callback = null) => {
    const modal = await this.modalController.create({
      component: DsDialogConfirmationComponent,
      componentProps: { title, message, type },
      cssClass: 'dialog-modal',
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(data => {
      if (callback) {
        callback(data);
      }
    });
    modal.present();
  };

}
