import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResultInterface } from '../../models/interfaces/http-list-meta-interface';
import { environment } from 'src/environments/environment';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudHttpService {

  private apiUrl = `${environment.driverApi}users`;

  constructor(private httpClient: HttpClient) { }

  get = async <T>(type: string, userId: string): Promise<HttpResultInterface<T>> => {
    const url = `${this.apiUrl}/${userId}/${type}`;
    return await this.httpClient.get<T>(url).toPromise()
      .then(data => ({ status: 200, data }))
      .catch(error => ({ status: error.status, error }));
  }

  getOne = async <T>(type: string, userId: string, id: string): Promise<HttpResultInterface<T>> => {
    const url = `${this.apiUrl}/${userId}/${type}/${id}`;
    return await this.httpClient.get<T>(url).toPromise()
      .then(data => ({ status: 200, data }))
      .catch(error => ({ status: error.status, error }));
  }

  save = async <T>(type: string, userId: string, body): Promise<HttpResultInterface<T>> => {
    const url = `${this.apiUrl}/${userId}/${type}`;
    return await this.httpClient.post<T>(url, body).toPromise()
      .then(data => ({ status: 200, data }))
      .catch(error => error);
  }

  update = async <T>(type: string, userId: string, body: any): Promise<HttpResultInterface<T>> => {
    const url = `${this.apiUrl}/${userId}/${type}`;
    return await this.httpClient.put<T>(url, body).toPromise()
      .then(data => ({ status: 200, data }))
      .catch(error => error);
  }

  delete = async <T>(type: string, userId: string, id: string): Promise<HttpResultInterface<T>> => {
    const url = `${this.apiUrl}/${userId}/${type}/${id}`;
    return await this.httpClient.delete<T>(url).toPromise()
      .then(data => ({ status: 200, data }))
      .catch(error => error);
  }

  streamImage = (url: string) => {
    return this.httpClient.get(url, { headers: { Accept: "image/webp,image/apng,image/svg+xml,image/*,*/*", "Content-Type": "image/png" }, responseType: "blob" })
      .toPromise()
      .then(data => ({ status: 200, data }))
      .catch(error => error);
  }
}
