import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DriverNotifsInterface } from 'src/app/models/interfaces/driver-notifs-interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {
  apiUrl = environment.driverApi;

  constructor(private httpClient: HttpClient) {
  }

    async getNotificationFolderState(userId) {
      const url = this.apiUrl + 'users/' + userId + '/driver-notification';

      return await this.httpClient
          .get<DriverNotifsInterface>(url)
          .toPromise()
          .then(data => ({status: 200, data}))
          .catch(error => error);
  }

  async upddateNotifReadAt(tabNotifId: Array<any>, userId) {
    console.log('tabNotifId', tabNotifId);
    console.log('userId passed', userId);
    const url = this.apiUrl + 'users/' + userId + '/update-notif-readAt';

    return await this.httpClient
        .put<Array<any>>(url, tabNotifId)
        .toPromise()
        .then(data => ({status: 200, data}))
        .catch(error => error);

}
}
