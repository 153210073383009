import { IUserFolder } from 'src/app/models/interfaces/userFolder-interface';
import { Injectable } from '@angular/core';
import { HttpResultInterface } from '../../models/interfaces/http-list-meta-interface';
import { CrudHttpService } from './crud.http.service';

@Injectable({
  providedIn: 'root',
})
export class TaxiFolderHttpService {
  private prefix = 'folder';

  constructor(private crudHttpService: CrudHttpService) { }

  getFolder = async (
    userId: string
  ): Promise<HttpResultInterface<IUserFolder>> => await this.crudHttpService.get<IUserFolder>(this.prefix, userId);

}
