/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastService } from '../shared/toast.service';
import { environment } from '../../../environments/environment';
import { HttpResultInterface } from '../../models/interfaces/http-list-meta-interface';

@Injectable({
  providedIn: 'root',
})
export class UserHttpServiceService {
  apiUrl = `${environment.authApi}auth`;

  constructor(private httpClient: HttpClient) {}

  /**
   * Login user
   *
   * @param credentials Credentials
   */
  async login(credentials: any) {
    const url = `${this.apiUrl}/login`;

    return await this.httpClient
      .post<any>(url, credentials)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => error);
  }

  async logout(token: string) {
    const url = `${this.apiUrl}/logout`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return await this.httpClient
      .post<any>(url, null, httpOptions)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => error);
  }

  async getMe(token: string, refresToken = false) {
    const url = `${this.apiUrl}/me` + (refresToken ? '?token=1' : '');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return await this.httpClient
      .post<any>(url, null, httpOptions)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => error);
  }

  async register(credentials): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/register`;
    return await this.httpClient
      .post(url, credentials)
      .toPromise()
      .then((data) => ({ status: 201, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error.errors,
      }));
  }

  async resetPassword(credentials): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/resetpassword`;
    return await this.httpClient
      .post(url, credentials)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async updateUser(user): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/updateme`;
    return await this.httpClient
      .post(url, user)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async setConsent(type): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/setconsent`;
    const postData = {
      consent_type: type,
    };
    return await this.httpClient
      .post(url, postData)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async unsetConsent(type): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/unsetconsent`;
    const postData = {
      consent_type: type,
    };
    return await this.httpClient
      .post(url, postData)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async setFavorite(favoriteId, place): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/setfavorite`;
    const postData = {
      favorite_id: favoriteId,
      place,
    };
    return await this.httpClient
      .post(url, postData)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async unsetFavorite(favoriteId): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/unsetfavorite`;
    const postData = {
      favorite_id: favoriteId,
    };
    return await this.httpClient
      .post(url, postData)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async checkDevices(token: string, code: string) {
    const url = `${environment.dataApi}device/users/verifyconsentments/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
    };
    return await this.httpClient
      .get(url, httpOptions)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => error);
  }

  async sendDevice(resources) {
    const url = `${environment.dispatchApi}v1/taxi/device`;
    return await this.httpClient
      .post<any>(url, resources)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => error);
  }

  async sendPublicDevice(resources) {
    const url = `${environment.dataApi}public/device`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return await this.httpClient
      .post<any>(url, resources, httpOptions)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => error);
  }

  async sendPhoneNumber(phoneNumber) {
    const url = `${this.apiUrl}/verifyphone`;
    const data = {
      phone_number: phoneNumber,
    };
    return await this.httpClient
      .post(url, data)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async checkEmailExist(email) {
    const url = `${this.apiUrl}/checkemailexist`;
    const data = {
      email,
    };
    return await this.httpClient
      .post(url, data)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async sendConnectedNotification() {
    const url = `${this.apiUrl}/notify-connected`;
    return await this.httpClient
      .post(url, null)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }

  async deleteAccount(): Promise<HttpResultInterface<any>> {
    const url = `${this.apiUrl}/forgotme`;
    return await this.httpClient
      .delete(url)
      .toPromise()
      .then((data) => ({ status: 200, data }))
      .catch((error) => ({
        status: error.status,
        message: error.message,
        error: error.error,
      }));
  }
}
