import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { UserService } from '../dataobject/user.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../shared/toast.service';
import { ErrorService } from '../shared/error.service';
import { ContextService } from '../context.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    public userService: UserService,
    public contextService: ContextService,
    public errorService: ErrorService,
    public toastService: ToastService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const platform: string = document.getElementsByClassName('plt-pwa').length > 0 ? 'pwa' : this.contextService.deviceInfo.platform;

    let headers = {
      Accept: request.headers.get("Accept") || "application/json",
      'Content-Type': request.headers.get("Content-Type") || "application/json",
      'X-Tenant': environment.tenant,
      'X-Application': 'driver',
      'X-DeviceUuid': this.contextService.deviceId.uuid,
      'X-AppVersion': platform === 'web' ? 'web' : this.contextService.appInfo.version,
      'X-Platform': platform,
    } as any;

    if (this.userService?.getToken()) {
      headers = { ...headers, Authorization: `Bearer ${this.userService.getToken()}` };
    }

    request = request.clone({ setHeaders: headers });

    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.url.indexOf('errors') < 0) {
          const data = {
            request_infos: {
              url: err.url,
            },
            error: {
              status: err.status,
              statusText: err.statusText,
              errContent: err.error,
              message: err.message,
            }
          };
          this.errorService.postItem(data);
        }
        return throwError(err);
      }));
  }
}
