import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {Coords, TAXIEXTERNALSTATUS, TaxiInterface, TaxiStatusInterface} from '../interfaces/taxi-interface';
import {Trip, Tripaddress} from '../interfaces/trip-interface';
import { Storage } from '@capacitor/storage';
import * as moment from "moment/moment";

export type TaxiStatusType = 'free' | 'occupied' | 'off';
// eslint-disable-next-line max-len
export type OccupationType =
  'free'
  | 'received_by_taxi'
  | 'accepted_by_taxi'
  | 'declined_by_taxi'
  | 'accepted_by_customer'
  | 'declined_by_customer'
  | 'pickuplocation'
  | 'customer_on_board'
  | 'finished'
  | 'canceled'
  | 'station'
  | 'incident_taxi'
  | 'incident_customer'
  | 'timeout_customer'
  | 'timeout_taxi'
  | 'timeout_accepted_by_customer'
  | 'failure'
  | 'uknown_error';

export interface MaraudeState {
  maraudeStatus: string;
  geolocationStatus: string;
  taxiStatus: string;
  taxiOccupation: OccupationType;
  hailsId?: string;
  customer?: any;
  hail?: any;
}

@Injectable({
  providedIn: 'root'
})
export class MaraudeStore {

  public taxiStatusUnexpectedSwitchOff: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public pingLocation: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private maraudeState$: BehaviorSubject<MaraudeState> = new BehaviorSubject({
    maraudeStatus: 'off',
    geolocationStatus: 'off',
    taxiStatus: 'off',
    taxiOccupation: 'free'
  });

  private taxi$: BehaviorSubject<TaxiInterface> = new BehaviorSubject<TaxiInterface>(null);
  private maraudeStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('off');
  private geolocationStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('off');
  private radius$: BehaviorSubject<number> = new BehaviorSubject<number>(500);
  private taxiLocation$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private taxiCoords$: BehaviorSubject<Coords> = new BehaviorSubject<Coords>(null);
  private showMaraudeInfos$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private taxiOccupation$ = new BehaviorSubject<OccupationType>('free');
  private taxiStatus$ = new BehaviorSubject<TaxiStatusType>('off');
  private taxiExternalStatus$ = new BehaviorSubject<string>(TAXIEXTERNALSTATUS.indisponible);
  private hailsId$ = new BehaviorSubject<string>(null);
  private hail$ = new BehaviorSubject<Trip>(null);
  private customer$ = new BehaviorSubject<any>(null);
  private chrono$ = new BehaviorSubject<number>(null);
  private countdownSub: Subscription;

  constructor() {
  }

  /**
   * update taxi status
   *
   * @param newTaxiStatus
   */
  updateTaxiStatus(newTaxiStatus: TaxiStatusInterface) {
    const taxi = this.taxi$.getValue();
    if(taxi.status !== newTaxiStatus.status) {
      if (taxi.status !== 'off' && newTaxiStatus.status === 'off') {
        this.taxiStatusUnexpectedSwitchOff.next(true);
      }
      taxi.status = newTaxiStatus.status;
      taxi.radius = newTaxiStatus.radius;
      this.setTaxi(taxi);
    }
    this.setExternalTaxiStatus(newTaxiStatus.external_status);
    this.setRadius(newTaxiStatus.radius);
  }

  restoreTaxiStatusSwitchOffState() {
    this.taxiStatusUnexpectedSwitchOff.next(false);
  }

  /******************************************************************************
   * Manage taxi data
   */
  get taxi(): Observable<TaxiInterface> {
    return this.taxi$.asObservable();
  }

  setTaxi(taxi: TaxiInterface) {
    if (taxi) {
      this.taxi$.next(taxi);
      this.radius$.next(taxi.radius);
      this.setTaxiStatus(taxi.status as TaxiStatusType);
    } else {
      this.taxi$.next(null);
      this.setTaxiStatus('off');
    }
  }

  /******************************************************************************
   * Manage show maraude infos
   */
  get showMaraudeInfos(): Observable<boolean> {
    return this.showMaraudeInfos$.asObservable();
  }

  setShowMaraudeInfos(flag: boolean) {
    this.showMaraudeInfos$.next(flag);
  }

  /******************************************************************************
   * Manage maraude status
   */
  get maraudeStatus(): Observable<string> {
    return this.maraudeStatus$.asObservable();
  }

  setMaraudeStatus(status: string) {
    this.setMaraudeStateProp('maraudeStatus', status);
    this.maraudeStatus$.next(status);
  }

  /******************************************************************************
   * Manage geolocation status
   */
  get geolocationStatus(): Observable<string> {
    return this.geolocationStatus$.asObservable();
  }

  setGeolocationStatus(status: string) {
    this.setMaraudeStateProp('geolocationStatus', status);
    this.geolocationStatus$.next(status);
  }

  /******************************************************************************
   * Manage radius status
   */
  get radius(): Observable<number> {
    return this.radius$.asObservable();
  }

  setRadius(radius: number) {
    this.radius$.next(radius);
  }

  getCurrentRadius() {
    return this.radius$.getValue();
  }

  /******************************************************************************
   * Manage taxi location
   */
  get taxiLocation(): Observable<any> {
    return this.taxiLocation$.asObservable();
  }

  setTaxiLocation(location: any) {
    // location.coords.latitude = 46.7667;
    // location.coords.longitude = -1.2333;
    this.taxiLocation$.next(location);
    //// update taxi coords
    this.taxiCoords$.next(Object.assign({}, {lat: location.coords.latitude, lng: location.coords.longitude}));
  }

  getCurrentTaxiLocation() {
    return this.taxiLocation$.getValue();
  }

  /******************************************************************************
   * Manage taxi coords
   */
  get taxiCoords(): Observable<any> {
    return this.taxiCoords$.asObservable();
  }

  /******************************************************************************
   * Manage taxi status
   */
  get taxiStatus(): Observable<TaxiStatusType> {
    return this.taxiStatus$.asObservable();
  }

  setTaxiStatus(status: TaxiStatusType) {
    if(this.taxiStatus$.getValue() !== status) {
      this.taxiStatus$.next(status);
      this.setMaraudeStateProp('taxiStatus', status);
    }
  }

  getCurrentTaxiStatus() {
    return this.taxiStatus$.getValue();
  }

  /******************************************************************************
   * Manage external taxi status
   */
  get taxiExternalStatus(): Observable<string> {
    return this.taxiExternalStatus$.asObservable();
  }

  setExternalTaxiStatus(status: string) {
    if(this.taxiExternalStatus$.getValue() !== status) {
      this.taxiExternalStatus$.next(status);
    }
  }

  getCurrentExternalTaxiStatus() {
    return this.taxiExternalStatus$.getValue();
  }

  /******************************************************************************
   * Manage taxi occupation
   */
  get taxiOccupation(): Observable<OccupationType> {
    return this.taxiOccupation$.asObservable();
  }

  setTaxiOccupation(occupation: OccupationType) {
    if(this.taxiOccupation$.getValue() !== occupation) {
      console.log('store taxis occupation');
      this.taxiOccupation$.next(occupation);
      this.setMaraudeStateProp('taxiOccupation', occupation);
    }
  }

  getCurrentTaxiOccupation() {
    return this.taxiOccupation$.getValue();
  }

  /******************************************************************************
   * Manage hail id
   */
  get hailsId(): Observable<string> {
    return this.hailsId$.asObservable();
  }

  setHailsId(hailsId: any) {
    const hail = this.getCurrentHail();
    hail.dispatcher_reference = hailsId;
    hail.internal_reference = 'LETAXI' + hailsId;
    this.setHail(hail);
    this.hailsId$.next(hailsId);
    this.setMaraudeStateProp('hailsId', hailsId);
  }

  getCurrentHailsId() {
    return this.hailsId$.getValue();
  }

  setHailProp(prop, value) {
    const hail = this.getCurrentHail();
    console.log(hail);
    hail[prop] = value;
    this.setHail(hail);
  }

  /******************************************************************************
   * Manage hail
   */
  get hail(): Observable<Trip> {
    return this.hail$.asObservable();
  }

  setHail(hail: Trip) {
    console.log('hail : ', hail);
    this.setMaraudeStateProp('hail', hail);
    this.hail$.next(hail);
  }

  getCurrentHail() {
    return this.hail$.getValue();
  }

  createHail() {
    const hail: Trip = {
      'internal_reference': '',
      'trip_reference': '',
      'ref_type_course': 'LETAXI',
      'course_type': 0, // 0: imediatly, 1: reservation
      'course_state': 4,
      'last_name': '',
      'first_name': '',
      'phone': '',
      'email': '',
      'date_time': '',
      'start_address': {'name':'', 'address':'', 'number':'', 'city':'', 'latitude':0, 'longitude':0},
      'dest_address':  {'name':'', 'address':'', 'number':'', 'city':'', 'latitude':0, 'longitude':0},
      'vehicle_type': '1',
      'passengers': 1,
      'animals': false,
      'comments': '',
      'direction': 0, // 0: aller, 1: aller/retour
      'payment_type': 'CB',
      'ct_code': '',
      'using_consent': true,
      'commercial_consent': false,
      'dispatcher_reference': '',
      'trip_price': 0,
      'trip_supplement': 0,
      'start_hour': '00:00',
      'end_hour': '00:00',
      'price_category': 'C',
      'taxi_id': (this.taxi$.getValue()).user_id.toString(10)
    };
    this.setHail(hail);
  }

  /******************************************************************************
   * Manage customer
   */
  get customer(): Observable<any> {
    return this.customer$.asObservable();
  }

  setCustomer(customer: any) {
    if(customer) {
      const hail = this.getCurrentHail();
      hail.phone = customer.customer_phone_number;
      hail.start_address.name = customer.customer_address;
      hail.start_address.latitude = customer.customer_lat;
      hail.start_address.longitude = customer.customer_lon;
      hail.ref_type_course = customer.origin;
      this.setHail(hail);
    }
    this.customer$.next(customer);
    this.setMaraudeStateProp('customer', customer);
  }

  getCurrentCustomer() {
    return this.customer$.getValue();
  }

  /******************************************************************************
   * Manage chrono
   */
  get chrono(): Observable<any> {
    return this.chrono$.asObservable();
  }

  setChrono(chrono: any) {
    this.chrono$.next(chrono);
  }

  startCountdown(length: number) {
    const T = timer(1000, 1000);
    if (this.countdownSub) {
      this.stopCountdown();
    }
    this.countdownSub = T.subscribe(val => {
      const down = length - val;
      console.log(down);
      this.setChrono(down);
    });
  }

  stopCountdown() {
    if (this.countdownSub) {
      this.countdownSub.unsubscribe();
      this.countdownSub = null;
      this.setChrono(null);
    }
  }

  /******************************************************************************
   * Manage MaraudeState
   */
  get maraudeState(): Observable<MaraudeState> {
    return this.maraudeState$.asObservable();
  }

  getCurrentMaraudeState() {
    return this.maraudeState$.getValue();
  }

  setMaraudeState(state: MaraudeState) {
    this.maraudeState$.next(state);
  }

  setMaraudeStateProp(prop, value) {
    const state = this.getCurrentMaraudeState();
    state[prop] = value;
    this.maraudeState$.next(state);
  }

  async storeMaraudeState(state: MaraudeState) {
    await Storage.set({
      key: 'atndriver.maraudestate',
      value: JSON.stringify(state),
    });
  }

  async loadMaraudeState(): Promise<MaraudeState | boolean> {
    const storageState = await Storage.get({ key: 'atndriver.maraudestate' });
    if(storageState)
    {
      const state = JSON.parse(storageState.value);
      console.log(state);
      this.maraudeState$.next(state);
      return JSON.parse(state);
    }
    return false;
  }

}
