import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
      private toastCtrl: ToastController,
      ) {
    }

    public async presentToast(message: string, position: 'top' | 'bottom' | 'middle' = 'top', duration: number = 5000) {
      (await this.toastCtrl.create({ message, duration, position })).present();
    }
}
