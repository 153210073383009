import { HttpResultInterface } from './../../models/interfaces/http-list-meta-interface';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  apiUrl = environment.dataApi + 'errors';

  constructor(private httpClient: HttpClient) { }

  async postItem(body: any): Promise<HttpResultInterface> {
    const url = this.apiUrl;

    return await this.httpClient
      .post<any>(url, body)
      .toPromise()
      .then(data => {
        return { status: data.status, data };
      })
      .catch(error => {
        return { status: error.status, error };
      });
  }


}
