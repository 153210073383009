import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './dataobject/user.service';
import {ContextService} from "./context.service";

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private userService: UserService, private contextService: ContextService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if(this.userService.isInitialized === false) return false;
        const a = await this.userService.isLoggedIn();
        const hasAllConsent = this.contextService.userHasAllConsent();
        const roles = route.data.roles as Array<string>;

        let hasRole = false;
        if (!roles) {
            hasRole = true;
        } else {
            roles.forEach(value => {
                if (this.userService.hasRole(value)) {
                    hasRole = true;
                }
            });
        }

        if (a && hasRole) {
          if(!hasAllConsent) {
            console.log('route CGV');
            this.router.navigate(['cgv']);
            return false;
          }
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }

}
