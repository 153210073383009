import {Injectable} from '@angular/core';
import BackgroundGeolocation, {Location} from '@transistorsoft/capacitor-background-geolocation';
import {environment} from '../../environments/environment';
import {UserService} from './dataobject/user.service';
import {ToastController} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {BehaviorSubject} from 'rxjs';
import {MaraudeStore} from '../models/store/maraude-store.service';
import {MaraudeHttpService} from "./api/maraude.http.service";

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(
    public userService: UserService,
    public contextService: ContextService,
    private toastCtrl: ToastController,
    private maraudeStore: MaraudeStore,
    private maraudeHttpService: MaraudeHttpService,
    private httpClient: HttpClient
  ) {
  }

  async launchGeolocation() {
    await BackgroundGeolocation.stop();
    await BackgroundGeolocation.removeListeners();

    ////
    // 1.  Wire up event-listeners
    //
    // This handler fires whenever bgGeo receives a location update.
    BackgroundGeolocation.onLocation(async (location: Location) => {
      console.log('On Location');
        if (!location) {
          return;
        }
      this.maraudeHttpService.sendPosition(location);

      this.maraudeStore.setTaxiLocation(location);
      }, ((error) => {  // <-- Location errors
        console.log('[onLocation] ERROR:', error);
      })
    );

    BackgroundGeolocation.onHttp(async (event) => {
      console.log('[onHttp]', event);
    });

    BackgroundGeolocation.onHeartbeat((event) => {
      BackgroundGeolocation.getCurrentPosition({}).then((location) => {
        console.log('On Heartbeat');
        if (!location) {
          return;
        }
        this.maraudeHttpService.sendPosition(location);
      });

    });

    ////
    // 2.  Execute #ready method (required)
    //
    BackgroundGeolocation.ready({
      reset: true,
      debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
      preventSuspend: true,
      heartbeatInterval: 60,
      // Geolocation Config
      // desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 25,
      // Activity Recognition
      stopTimeout: 1,
      // Application config
      locationUpdateInterval: 10000,
      locationAuthorizationRequest: 'Always',
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
      stopOnTerminate: false,   // <-- Allow the background-service to continue tracking when user closes the app.
      startOnBoot: true,        // <-- Auto start tracking when device is powered-up.
      // HTTP / SQLite config
      // url: 'https://api-dispatch.taxis-nantes.com/api/v1/taxi/storeposition',
      batchSync: false,       // <-- [Default: false] Set true to sync locations to server in a single HTTP request.
      autoSync: true,         // <-- [Default: true] Set true to sync each location to server as it arrives.
      persistMode: BackgroundGeolocation.PERSIST_MODE_NONE,
      triggerActivities: 'in_vehicle,still,on_bicycle,on_foot,running',
      headers: {              // <-- Optional HTTP headers
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${this.userService.getToken()}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Tenant': environment.tenant,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Application': 'driver',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-DeviceUuid': this.contextService.deviceId.uuid,
      },
      params: {               // <-- Optional HTTP params append to each HTTP request
      },
      extras: {               // <-- Optional meta-data appended to each recorded location.
      }
    }).then((state) => {

      if (!state.enabled) {
        ////
        // 3. Start tracking!
        //
        BackgroundGeolocation.start().then(() => {
          this.maraudeStore.setGeolocationStatus('on');
          return true;
        })
          .catch(() => {
            this.maraudeStore.setGeolocationStatus('off');
            return false;
          });
      }
    }).catch((error) => {
      this.maraudeStore.setGeolocationStatus('off');
      return false;
    });
  }

  /**
   *
   */
  async stopGeolocation() {
    BackgroundGeolocation.stop().then((state) => {
      this.maraudeStore.setGeolocationStatus('off');
      console.log('BackgroundGeolocation is stop ', state);
    })
      .catch((error) => {
        console.warn('[BackgroundGeolocation stop] ERROR: ', error);
      });
  }
}
